import React from "react";
import ReactApexChart from "react-apexcharts";

const Graph: React.FC<{ data: any }> = ({ data }) => {
  const series = React.useMemo(() => {
    if (data && data.series) {
      return data.series;
    } else {
      return [
        {
          name: "Converted",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Leads",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Visitors",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ];
    }
  }, [data]);


  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: "42%",
      },
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: data?.months || [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    grid: {
      borderColor: '#F2F4F7', // Set grid line color
      strokeDashArray: 0,  // Set to 0 for solid lines
    },
    yaxis: {
      title: {
        text: undefined,
      },
      tickAmount: 5,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    colors: ["#0F394B", "#3D7B95", "#7FC5E3"],
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={347}
        />
      </div>
    </div>
  );
};

export default Graph;
