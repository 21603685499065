import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Signin from "./pages/auth/sign-in.tsx";
import SignUp from "./pages/auth/sign-up.tsx";
import VerifyEmail from "./pages/auth/verify-email.tsx";
import ForgotPassword from "./pages/auth/forgot-password.tsx";
import CheckEmail from "./pages/auth/check-email.tsx";
import NewPassword from "./pages/auth/new-password.tsx";
import Deactivated from "./pages/auth/deactivated.tsx";
import SuccessfullyReset from "./pages/auth/successfully-reset.tsx";
import Dashboard from "./pages/dashboard/index.tsx";
import Payouts from "./pages/payouts/index.tsx";
import Settings from "./pages/settings/index.tsx";
import Contact from "./pages/contact/index.tsx";
import "react-toastify/dist/ReactToastify.css";
import { GuestGuard } from "./components/guest-guard.ts";
import { useAuthContext } from "./context/auth-context.tsx";
import useOpenOrganization from "./hooks/use-organization.tsx";
import useOpenCommission from "./hooks/use-commission.tsx";
import useOpenTeams from "./hooks/use-teams.tsx";
import useOpenPayouts from "./hooks/use-payouts.tsx";
import useOpenActivity from "./hooks/use-activity.tsx";
import useOpenStats from "./hooks/use-stats.tsx";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store.ts";
import { AuthGuard } from "./components/auth-guard.ts";
import Partners from "./pages/partner/index.tsx";

// Higher Order Component for Protected Routes

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { user } = useAuthContext();

  const organization = useSelector(
    (state: RootState) => state.organization.organization
  );

  const commissions = useSelector(
    (state: RootState) => state.commission.commission
  );

  const stats = useSelector((state: RootState) => state.stats.stats);
  const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const activity = useSelector((state: RootState) => state.activity.activity);

  const { isAuthenticated } = useAuthContext();
  const { getOrganizations } = useOpenOrganization();
  const { getCommission } = useOpenCommission();
  const { getTeams } = useOpenTeams();
  const { getStats } = useOpenStats();
  const { getPayouts } = useOpenPayouts();
  const { getActivity } = useOpenActivity();

  useEffect(() => {
    if (isAuthenticated) {
      if (user && commissions?.length === 0) {
        getCommission();
      }

      if (user && payouts?.length === 0) {
        getPayouts(user);
      }
      if (user && stats?.length === 0) {
        getStats();
      }

      if (activity?.length === 0) {
        getActivity();
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isSmallScreen) {
    return (
      <div className="h-[100vh] w-full flex items-center justify-center">
        It is not available for mobile and tab screen
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuestGuard>
            <Signin />
          </GuestGuard>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        }
      />
      <Route
        path="/payouts"
        element={
          <AuthGuard>
            <Payouts />
          </AuthGuard>
        }
      />
        <Route
        path="/partner"
        element={
          <AuthGuard>
            <Partners />
          </AuthGuard>
        }
      />
      <Route
        path="/settings"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />
      <Route
        path="/contact"
        element={
          <AuthGuard>
            <Contact />
          </AuthGuard>
        }
      />

      {/* Auth Routes */}
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/forget-password" element={<ForgotPassword />} />
      <Route path="/check-email" element={<CheckEmail />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/successfully-reset" element={<SuccessfullyReset />} />
      <Route path="/deactivated" element={<Deactivated />} />
    </Routes>
  );
}

export default App;