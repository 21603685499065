import React, { useState } from "react";
import { Row, Col } from "antd";
import ContactStep from "./contact-step/index.tsx";

const Main = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`container ${isActive ? 'active' : ''}`} style={{paddingTop:"70px"}}>
      <Row>
        <Col span={8} className="mt-10">
          <ContactStep setIsActive={setIsActive} />
        </Col>
        <Col span={16} className="w-full object-fill flex justify-end mt-10 ">
          <img
            src="https://ik.imagekit.io/8extk8sjo/Commmission%20Contact.png?updatedAt=1710315225980"
            alt=""
            className=""
          />
        </Col>
      </Row>
    </div>
  );
};

export default Main;
