import React from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="/images/deactivated.svg"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Your account has been <br/> deactivated.
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">

              </Text>
              <Text
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] pt-6"
              >
                If you need help with this contact us at
                <br/>
                <span className="text-[#004EEB] cursor-pointer font-semibold text-[14px]">
                  partnerships@glovendor.io
                </span>
              </Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
