import React from "react";
import { Table, Avatar, Tag, Space, Button, Checkbox, Modal, Col, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Delete from "../../assets/images/deleted.png";
import Crossed from "../../assets/images/crosed.png"
import Delets from "../../assets/images/delets.png"
import { Grid } from "@mui/material";
import LabelInput from "../../components/labelInput.tsx";
import moment from "moment";
import axiosClient from "../../utils/axios.tsx";
import { toast } from "react-toastify";

interface User {
  id: number;
  name: string;
  email: string;
  country: string;
  commission: string;
  invited?: boolean;
  avatarUrl?: string;
}

const PartnerTable: React.FC<{ data: any; refresh: any }> = ({
  data,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [ModalOpen, setModalOpen] = React.useState(false);
  const [selectedItem, setselectedItem] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 10;

  const showModal = (item) => {
    setselectedItem(item);
    setIsModalOpen(true);
  };
 
  const showRemoveModal = (item) => {
    setselectedItem(item);
    setModalOpen(true);
  };

  const removeInvite = () => {
    const invite_id = selectedItem?.id;
    setIsLoading(true);
    axiosClient()
      .delete(`invites/${invite_id}`)
      .then((res) => {
        toast.success("Invite cancled successfully");
        refresh();
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setIsLoading(false);
        handleCancel(); //to close modal
      });
  };

  const handleCancel = () => {
    setselectedItem(null);
    setIsModalOpen(false);
    setModalOpen(false);
  };

  const handleRemove = () => {
    const userId = selectedItem?.user_id;
    toast.error(`Removed ${selectedItem?.name} !`)
    axiosClient()
      .patch(`/users/${userId}`, {
        is_active: false,
      })
      .then((res) => {
        toast.success(`${selectedItem?.name} removed successfully!`);
        refresh();
        handleCancel(); //to close modal
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  const columns = [
    {
      title: <> <span className="idPartner"><Checkbox></Checkbox> Id</span> </>,
      dataIndex: "user_id",
      key: "id",
      render: (text: number) => <Checkbox>{text}</Checkbox>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: User) => (
        <Space size="middle">
          <Avatar
            src={record?.profile_image}
            icon={!record?.profile_image && <UserOutlined />}
          />
          {record.id ? (
            <Tag color="default" className="inviteTag">
              Invited
            </Tag>
          ) : (
            record.name
          )}
        </Space>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "location",
      key: "country",
    },
    {
      title: "Commission Model",
      dataIndex: ["commission_model_id", "name"],
      key: "commission",
      render: (text: string, record: any) => {
        return record.commission_model_id
          ? record.commission_model_id.name
          : "-";
      },
    },
    {
      title: "",
      key: "actions",
      render: (_: any, record: User) => (
        <div className="actionLink">
          {record.id ? (
            <>
              <Button type="link" onClick={() => showRemoveModal(record)}>
                Remove
              </Button>
            </>
          ) : (
            <>
              <Button type="link" onClick={() => showModal(record)}>
                View
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <div
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
        }}
        className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden mt-3  partnerBox"
      >
        <Table
          className="innerPartner views"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          rowKey="id"
        />
           <div className="w-full dashboardBottom bg-white h-20 items-center  px-4  flex justify-between gap-4">
                    <span className="text-[#344054] text-[14px] font-medium ">
                        Page {currentPage} of {Math.ceil(data.length / pageSize)}
                    </span>

                    <div className="flex items-center gap-3">
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                          disabled={currentPage === Math.ceil(data.length / pageSize)}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
      </div>

      {/* view modal start */}

      <Modal
        className="modalViewPopup pops"
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Col className="headerView" style={{position:"relative"}}>
          <img
            src={
              selectedItem?.profile_image
                ? selectedItem?.profile_image
                : "/images/profile.svg"
            }
            alt=""
          />
          <Col>
            <h3>{selectedItem?.name}</h3>
            <h6>Id: {selectedItem?.user_id}</h6>
          </Col>
          <img src={Crossed} alt="" className="crseedTick" onClick={()=>setIsModalOpen(false)} />
        </Col>
        <Grid container className="gridBox">
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Email"
                placeholder=""
                value={selectedItem?.email}
              />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Country"
                placeholder=""
                value={selectedItem?.location}
              />
            </Col>
          </Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Phone Number"
                placeholder=""
                value={selectedItem?.phone}
              />
            </Col>
          </Grid>
          <Grid xs={0.2}></Grid>
          <Grid xs={5.9}>
            <Col>
              <LabelInput
                title="Created At"
                placeholder=""
                value={
                  selectedItem?.created_at
                    ? moment(selectedItem?.created_at).format("MMMM Do YYYY")
                    : "NA"
                }
              />
            </Col>
          </Grid>
          <Grid xs={12}>
            <Col>
              <LabelInput
                title="Commission Model"
                placeholder=""
                value={selectedItem?.commission_model_id?.name}
              />
            </Col>
          </Grid>
          <Grid xs={12}>
            <Col>
              <LabelInput
                title="Last Login"
                placeholder=""
                value={
                  selectedItem?.token_created_at
                    ? moment(selectedItem?.token_created_at).format(
                        "MMMM Do YYYY"
                      )
                    : "NA"
                }
              />
            </Col>
          </Grid>
        </Grid>
      </Modal>

      {/* remove modal start */}
      <Modal
        className="modalViewPopup newRemove"
        open={ModalOpen}
        onCancel={handleCancel}
      >
        <Col className="headerView">
          <img src={Delete} alt="" className="desktop" />
          <img src={Delets} alt="" className="mobile" />
          <Col>
            <h5>Remove {selectedItem?.id ? selectedItem?.email : selectedItem?.name}?</h5>
            <p className="pText">
              Are you sure you want to remove them? This action cannot be
              undone.
            </p>
          </Col>
        </Col>

        <Col className="removeBtn">
          <Button onClick={handleCancel}>Cancel</Button>
          {selectedItem?.id ?
          <Button onClick={removeInvite}>{ isLoading ? <Spin/> : "Remove"}</Button>
          : 
          <Button onClick={handleRemove}>{ isLoading ? <Spin/> : "Remove"}</Button>
          }
        </Col>
      </Modal>
    </>
  );
};

export default PartnerTable;
