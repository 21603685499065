import { Button, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import Head from "../../../../src/assets/images/head.svg";
import Circle from "../../../../src/assets/images/circle.png";
import Fill from "../../../../src/assets/images/fill.png";
import Chat from "../../../../src/assets/images/Chat.svg";
import InputSection from '../input-section.tsx';
import Heading from '../../../components/heading.tsx';

export default function ContactStep({ setIsActive }) {
  const [meeting, setMeeting] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (meeting) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [meeting, setIsActive]);

  return (
    <>
      {meeting ? (
        <Heading
          heading="Book a Meeting"
          subHeading="Leave Glovender team a message and we’ll get right back to you."
        />
      ) : (
        <>
          {message ? (
            <Heading
              heading="Send Us a Message"
              subHeading="Leave Glovender team a message and we’ll get right back to you."
            />
          ) : (
            <Heading
              heading="Contact Us"
              subHeading="Leave Glovender team a message and we’ll get right back to you."
            />
          )}
        </>
      )}
      {meeting ? (
        <Col className="iframe m-5">
          <iframe src="https://calendly.com/d/ckjc-3xk-kh9/referral-partner-exploration-support-session?month=2024-07"></iframe>
        </Col>
      ) : (
        <>
          {message ? (
            <InputSection />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
                margin: "36px 0 50px",
                width: "95%"
              }}
            >
              <Col className={meeting ? 'boxedContact active' : "boxedContact"} onClick={() => setMeeting(!meeting)}>
                <img src={Head} alt="" />
                <Col className="meetingBox">
                  <h4>Book a Meeting</h4>
                  {meeting ? <img src={Fill} alt="" /> : <img src={Circle} alt="" />}
                </Col>
              </Col>
              <Col className={message ? 'boxedContact active' : "boxedContact"} onClick={() => setMessage(!message)}>
                <img src={Chat} alt="" />
                <Col className="meetingBox">
                  <h4>Send a message</h4>
                  {message ? <img src={Fill} alt="" /> : <img src={Circle} alt="" />}
                </Col>
              </Col>
            </div>
          )}
        </>
      )}
      {meeting ? "" : <>{message ? "" : <Button className="btnSubmit">Continue</Button>}</>}
    </>
  );
}
