import axiosClient from "../utils/axios.tsx";

class Payouts {
  async getAllPayouts(user, values?) {
    const today = new Date();
    const currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() + 1);
    const todayISOString = currentDate.toISOString().split("T")[0];

    const legacyDate = new Date("01/01/2022");
    const legacyDateISOString = legacyDate.toISOString().split("T")[0];

    try {
      const payouts = await axiosClient().get(
        `users-commissions/super-partner/formatted?user_id=${
            user?.user_id
          }&StartDate=${
            values?.from ? values?.from : legacyDateISOString
          }&EndDate=${
            values?.to ? values?.to : todayISOString
          }`
      );

      return payouts.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const payoutApi = new Payouts();
