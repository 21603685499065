import React from "react";
import type { TableColumnsType } from "antd";
import { Table, Tooltip } from "antd";

interface DataType {
  key: string;
  company: string;
  referred: string;
  payout: string;
  amount: string;
  total: string;
  status: any;
  view: any;
}

const TableSection: React.FC<{
  setIsViewOpen: any;
  isShow: boolean;
  payouts: any;
}> = ({ setIsViewOpen, isShow, payouts }) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <span className="flex items-center gap-2">
          Date
        </span>
      ),
      dataIndex: "date",
      key: "date",
      className: "dateSelect",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Payments made may take up to 3-5 days to reflect on your bank account. "
        >
          <span className="flex items-center gap-2">Company</span>
        </Tooltip>
      ),
      dataIndex: "company",
      key: "company",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Event
        </span>
      ),
      dataIndex: "event",
      key: "event",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission Percent
        </span>
      ),
      dataIndex: "percent",
      key: "percent",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission amount
        </span>
      ),
      dataIndex: "c_amount",
      key: "c_amount",
    },
    {
      title: (
        <Tooltip placement="top" title="Payments made may take up to 3-5 days to reflect on your bank account. ">
          <span className="flex items-center gap-2">
            Status
            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(36).svg?updatedAt=1711585625996"
              alt=""
            />
          </span>
        </Tooltip>
      ),
      dataIndex: "View",
      key: "View",
    },
  ];

  return isShow ? (
    <Table
    className="partnerTable edits"
      columns={columns}
      dataSource={payouts}
      pagination={false}
    />
  ) : null;
};



export default TableSection;
