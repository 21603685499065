import axiosClient from "../utils/axios.tsx";

class Organization {
  async getAllOrganizations(user) {
    try {
      const organization = await axiosClient().get(
        `pipedrive-organisations/${user?.user_id}`
      );

      return organization.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const organizationApi = new Organization();
