import React from "react";
import { Typography, Input, Button, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Light from "../../assets/images/light1.png"
import Dark from "../../assets/images/dark1.png"
import axiosClient from "../../utils/axios.tsx";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const PasswordSection = (): JSX.Element => {
  const { Text } = Typography;

  const id = Cookies.get("super-partner-user-id");

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const data = {
          password: values?.currentPassword,
          new_password: values?.newPassword,
        };

        axiosClient()
          .patch(`/users/password/${id}`, data)
          .then((res) => {
            toast.success("Password updated successfully");
            setSubmitting(false);
            resetForm();
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="passwordTheme">
            <div className="w-full" style={{position:"relative"}}>
            <img src={Light} alt="" className="lightimg" />
            <img src={Dark} alt="" className="darkimg" />
              <Text className="text-[#344054] text-[14px] font-medium">
                Current Password
              </Text>
              <Field
                name="currentPassword"
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                    placeholder="Current password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                )}
              />
             
            </div>
            <ErrorMessage
                name="currentPassword"
                component="div"
                className="text-red-500"
              />
            <div className="w-full mt-4" style={{position:"relative"}}>
            <img src={Light} alt="" className="lightimg" />
            <img src={Dark} alt="" className="darkimg" />
              <Text className="text-[#344054] text-[14px] font-medium">
                New Password
              </Text>
              <Field
                name="newPassword"
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                    placeholder="New password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                )}
              />
           
            </div>
            <ErrorMessage
                name="newPassword"
                component="div"
                className="text-red-500"
              />
            <div className="w-full mt-4" style={{position:"relative"}}>
            <img src={Light} alt="" className="lightimg" />
            <img src={Dark} alt="" className="darkimg" />
              <Text className="text-[#344054] text-[14px] font-medium">
                Confirm Password
              </Text>
              <Field
                name="confirmPassword"
                render={({ field }) => (
                  <Input.Password
                    {...field}
                    className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
                    placeholder="Confirm password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                )}
              />

            </div>
            <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500"
              />
            <div className="border-t w-full mt-6 darkBorder">
              <div className="pt-4 flex gap-3 items-center justify-end">
                <Button className="h-[44px] bg-[#fff] cancel rounded-[8px] font-semibold text-[16px] border border-[#D0D5DD] shadow-none text-[#344054]">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  className="h-[44px] min-w-[130px] changes bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                >
                  {isSubmitting ? <Spin /> : " Save changes"}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordSection;
