import React, { useState, useEffect, useRef } from "react";
import { Logo } from "../../../components/index.ts";
import Logos from "../../../assets/images/logos.png";
import { Typography, Button, Spin, Col, Row, Checkbox } from "antd";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import axiosClient from "../../../utils/axios.tsx";
import Select from "react-select";
import { allCountries } from "country-telephone-data";
import PhoneNumberInput from "./phone-number.tsx";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  company: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  country: Yup.object().nullable().required("Country is required"),
  agree: Yup.boolean().oneOf(
    [true],
    "You must agree to the terms and conditions"
  ),
});

const fetchUserCountry = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data;
  } catch (error) {
    console.error("Error fetching user country:", error);
    return null;
  }
};

const SignupForm = () => {
  const formikRef = useRef(null);
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCountry, setIsLoadingCountry] = useState(true);

  const countryOptions = allCountries.map((country) => ({
    value: country.iso2,
    label: country.name.split("(")[0].trim(), // This will take only the part before any parentheses
    dialCode: country.dialCode,
  }));

  const CustomIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Social%20icon.svg?updatedAt=1709103355258"
        alt=""
        width={`25px`}
      />
    );
  };

  useEffect(() => {
    const getUserCountry = async () => {
      setIsLoadingCountry(true);
      const userCountryData = await fetchUserCountry();
      if (userCountryData) {
        const countryOption = countryOptions.find(
          (option) =>
            option.value === userCountryData.country_code.toLowerCase()
        );
        if (countryOption) {
          setSelectedCountry(countryOption);
          if (formikRef.current) {
            formikRef.current.setFieldValue("country", countryOption);
          }
        }
      }
      setIsLoadingCountry(false);
    };

    getUserCountry();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      axiosClient()
        .get("/users/super-partner/google/sign-up", {
          headers: {
            "google-token": tokenResponse.access_token,
          },
        })
        .then((res) => {
          setIsLoading(false);
          toast.success("Thanks for signing up");
          navigate("/signin");
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          toast.error(error.response.data.message);
        });
    },
    onError: () => {
      setIsLoading(false);
      console.log("Google Authentication Failed");
      toast.error("Google Authentication Failed");
    },
  });

  const handleGoogleLoginClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    googleLogin();
  };

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <div
        className=" flex items-center justify-center pt-10"
        style={{ width: "413px", margin: "0 auto" }}
      >
        <div className=" h-full" style={{ width: "413px", margin: "0 auto" }}>
          <Col className="logoImg">
            <Logo />
            <img src={Logos} className="w-[180px] mobile" alt="" />
          </Col>
          <Title
            className="pt-20"
            style={{
              fontWeight: "600",
              fontFamily: "Inter",
              paddingTop: "60px",
              fontSize: "36px",
              color: "#101828",
            }}
          >
            Sign up
          </Title>
          <Text className="text-[16px] text-[#667085] spans">
            Enter your details to become an affiliate and start earning
            commission!
          </Text>
          <Formik
            innerRef={formikRef}
            initialValues={{
              name: "",
              company: "",
              email: "",
              password: "",
              user_type_id: 4,
              role: "SUPER_PARTNER",
              user_summary: "",
              phone: "",
              country: null,
              agree: true,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                ...values,
                location: selectedCountry ? selectedCountry?.label : "",
                country_code: selectedCountry ? selectedCountry?.value : "",
                phone: values?.phone?.split(" ")?.join(""),
              };
              axiosClient()
                .post("/users", data)
                .then((res) => {
                  toast.success("Thanks for signing up");
                  setSubmitting(false);
                  navigate("/check-email", {
                    state: { email: values.email, type: "VERIFY_EMAIL" },
                  });
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="pt-6">
                  <Row className="mb-0">
                    <Col md={12}>
                      <Col style={{ marginRight: "10px" }}>
                        <div className="mb-2">
                          <Title
                            level={5}
                            style={{
                              color: "#344054",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Name*
                          </Title>
                          <Field
                            type="name"
                            name="name"
                            placeholder="Enter your name"
                            className="signInput"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </Col>
                    </Col>
                    <Col md={12}>
                      <Col>
                        <div className="mb-4">
                          <Title
                            level={5}
                            style={{
                              color: "#344054",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Company Name
                          </Title>
                          <Field
                            type="company"
                            name="company"
                            placeholder="Enter company name"
                            className="signInput"
                          />
                          <ErrorMessage
                            name="company"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>

                  <div className="mb-4">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Country*
                    </Title>
                    <Field name="country">
                      {({ field, form, meta }) => (
                        <div>
                          {isLoadingCountry ? (
                            <Spin />
                          ) : (
                            <Select
                              {...field}
                              options={countryOptions}
                              onChange={(option) => {
                                form.setFieldValue("country", option);
                                form.setFieldTouched("country", true, false);
                                setSelectedCountry(option);
                              }}
                              onBlur={() =>
                                form.setFieldTouched("country", true)
                              }
                              value={form.values.country}
                              classNamePrefix="custom-select"
                              className="selectInput"
                              placeholder="Select a country"
                            />
                          )}
                          {meta.touched && meta.error && (
                            <div className="text-red-500">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="mb-4">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Email*
                    </Title>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="signInput"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <Col className="phoneNumber mb-4">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Phone Number
                    </Title>
                    <Field
                      name="phone"
                      component={PhoneNumberInput}
                      selectedCountry={selectedCountry}
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500"
                    />
                  </Col>

                  <div className="mb-2">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Password*
                    </Title>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Create a password"
                      className="signInput"
                    />
                    <h6 className="charterPassword">
                      Must be at least 8 characters.
                    </h6>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="mt-3 flex items-start ">
                    <Field type="checkbox" name="agree" className="mr-2 formikCheck" />
                    <Text className="text-[#344054] text-[14px] font-medium">
                      I agree to Glovendor partner{" "}
                      <span
                        onClick={() =>
                          window.open(
                            "https://www.glovendor.io/partner-portal-terms-of-service",
                            "_blank"
                          )
                        }
                        className="text-[#155EEF] cursor-pointer"
                      >
                        terms of service
                      </span>
                      {" and "}
                      <span
                        onClick={() =>
                          window.open(
                            "https://www.glovendor.io/partner-portal-privacy-policy",
                            "_blank"
                          )
                        }
                        className="text-[#155EEF] cursor-pointer"
                      >
                        privacy policy
                      </span>
                    </Text>
                  </div>
                  <ErrorMessage
                    name="agree"
                    component="div"
                    className="text-red-500"
                  />

                  <Button
                    htmlType="submit"
                    disabled={isSubmitting}
                    className="w-full h-[44px] signups mt-4 bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                  >
                    {isSubmitting ? <Spin /> : "Sign Up"}
                  </Button>

                  <Button
                    icon={<CustomIcon />}
                    onClick={handleGoogleLoginClick}
                    className="w-full h-[44px] google bg-[#fff] mt-4 shadow-none border-[1.5px] border-[#D0D5DD] rounded-[8px] font-semibold text-[16px] text-[#344054] flex items-center justify-center gap-1"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spin /> : "Sign up with Google"}
                  </Button>

                  <div className="text-center mt-6 pt-2">
                    <Text style={{ color: "#475467" }} className="text-[14px]">
                      <span
                        style={{ borderBottom: "1px solid #475467 " }}
                        className="accountHave"
                      >
                        {" "}
                        Already have an account?{" "}
                      </span>
                      <span
                        onClick={() => navigate("/signin")}
                        className="text-[#004EEB] cursor-pointer font-semibold text-[14px] loginHave"
                      >
                        Log in
                      </span>
                    </Text>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignupForm;
