import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Typography, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../../utils/axios.tsx";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/auth-context.tsx";

const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState("Email Verification Failed");
  const didRequest = useRef(false);

  const { verifiedLogIn } = useAuthContext();

  useEffect(() => {
    if (email && !didRequest.current) {
      didRequest.current = true;
      axiosClient()
        .patch(`/users/verify-email/${email}`)
        .then((res) => {
          setIsLoading(false);
          setResult("Email Verified");
          toast.success("Email Verified Successfully!");
          verifiedLogIn(res?.data?.user, res?.data?.token)
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  },[email]);

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon.svg?updatedAt=1709120630375"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Verifying your email
            </Title>
            <div className="grid text-center mt-5">
              {isLoading ? <Spin /> : `${result}`}
              <div className="text-center mt-8 flex justify-center gap-2 items-center ">
                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                  alt=""
                />
                <Text
                  onClick={() => navigate("/signin")}
                  style={{
                    color: "#475467",
                  }}
                  className=" text-[14px] cursor-pointer font-semibold"
                >
                  Back to log in
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
