import React from "react";
import { Typography } from "antd";

type itemProps = {
  label: string;
  image: string;
  count: number;
};

const Card = ({ label, count, image }: itemProps): JSX.Element => {
  const { Text } = Typography;
  return (
    <div
      style={{
        alignItems:"center",
       
      }}
      className="dashboardCard flex"
    >
      <img src={image} alt={label} />
      <div className="grid" style={{marginLeft:"14px"}}>
        <Text className="#475467 text-[16px] font-medium labelCard right">{label}</Text>
        <Text className="text-[#101828] text-[30px] font-semibold labelCount">{count}</Text>
      </div>
    </div>
  );
};

export default Card;
