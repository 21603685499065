import React from "react";
import { Table, Avatar, Tag, Space, Button, Modal, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Grid } from "@mui/material";
import LabelInput from "../../components/labelInput.tsx";
import Crossed from "../../assets/images/crosed.png";
import Avatars from "../../assets/images/avatar.png";
import moment from "moment";

interface User {
  id: number;
  name: string;
  date: string;
  company: string;
  commission: string;
  status: string;
  avatarUrl?: string;
}

const FinanceTable: React.FC<any> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [currentPage,setCurrentPage] = React.useState(1);
  const pageSize = 10;

  const showModal = (item) => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: User) => (
        <Space size="middle">
          <Avatar
            src={record?.image || ""}
            icon={!record?.avatarUrl && <UserOutlined />}
          />
          {record.name}
        </Space>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Commission Amount",
      dataIndex: "commission",
      key: "commission",
      render: (text: string) => `$${text ? parseFloat(text)?.toFixed(2) : 0}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <Tag className="colors" color={text === "Paid" ? "green" : "orange"}>
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (record: User) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedItem(record);
            showModal(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
};

const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: data?.length,
    onChange: handlePageChange,
    showSizeChanger: false,
};


  return (
    <>
      <div
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
        }}
        className="rounded-[12px] financeTable border border-[#EAECF0] bg-white overflow-hidden mt-3"
      >
        <Table
          className="partnerTable finance"
          columns={columns}
          dataSource={data}
          pagination={paginationConfig}
          rowKey="id"
        />
           <div className="w-full dashboardBottom bg-white h-20 items-center  px-4  flex justify-between gap-4">
                    <span className="text-[#344054] text-[14px] font-medium ">
                        Page {currentPage} of {Math.ceil(data?.length/pageSize)}
                    </span>

                    <div className="flex items-center gap-3">
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                          disabled={currentPage === Math.ceil(data?.length / pageSize)}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>

        {/* view modal start */}

        <Modal
          className="modalViewPopup pops"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Col className="headerView" style={{ position: "relative" }}>
            <img src={selectedItem?.image || "/images/profile.svg"} alt="" />
            <Col>
              <h3>{selectedItem?.name}</h3>
              <h6>Id: {selectedItem?.id}</h6>
            </Col>
            <img
              src={Crossed}
              alt=""
              className="crseedTick"
              onClick={() => {
                setSelectedItem({});
                setIsModalOpen(false);
              }}
            />
          </Col>
          <Grid container className="gridBox">
            <Grid xs={5.9}>
              <Col>
                <LabelInput title="Company" value={selectedItem?.company} />
              </Col>
            </Grid>
            <Grid xs={0.2}></Grid>
            <Grid xs={5.9}>
              <Col>
                <LabelInput
                  title="Revenue Amount"
                  value={`$${
                    selectedItem?.revenue
                      ? selectedItem?.revenue?.toFixed(2)
                      : 0
                  }`}
                />
              </Col>
            </Grid>
            <Grid xs={5.9}>
              <Col>
                <LabelInput
                  title="Commission Amount"
                  value={`$${
                    selectedItem?.commission
                      ? selectedItem?.commission?.toFixed(2)
                      : 0
                  }`}
                />
              </Col>
            </Grid>
            <Grid xs={0.2}></Grid>
            <Grid xs={5.9}>
              <Col>
                <label>Payment Status</label>
                <div class="flex items-center">
                  <span
                    className="text-[14px] paidStatus font-normal w-auto h-auto rounded-[16px] p-1 px-3 capitalize mt-2"
                    style={
                      selectedItem?.status === "Unpaid"
                        ? { backgroundColor: "#FFFAEB", color: "#B54708" }
                        : selectedItem?.status === "Paid"
                        ? { backgroundColor: "#ECFDF3", color: "#027A48" }
                        : {}
                    }
                  >
                    {selectedItem?.status}
                  </span>
                </div>
              </Col>
            </Grid>

            <Grid xs={5.9}>
              <Col>
                <LabelInput
                  title="Commission %"
                  value={`${
                    selectedItem?.percentage
                      ? selectedItem?.percentage?.toFixed(2)
                      : 0
                  }%`}
                />
              </Col>
            </Grid>
            <Grid xs={0.2}></Grid>
            <Grid xs={5.9}>
              <Col>
                <LabelInput
                  title="Payout Date"
                  value={
                    selectedItem?.date
                      ? moment(selectedItem?.date).format("MMMM Do YYYY")
                      : ""
                  }
                />
              </Col>
            </Grid>
          </Grid>
        </Modal>
      </div>
    </>
  );
};

export default FinanceTable;
