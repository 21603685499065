import React, { useState } from "react";
import { Table, Tag, Space, Button } from "antd";
import { StatusBtn } from "../../components/index.ts";
import Cookies from "js-cookie";
import moment from "moment";

const DashboardTable: React.FC<any> = ({ tableData }) => {
    const userId = Cookies.get("super-partner-user-id");
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // You can adjust this value as needed

    const dataSource = tableData
        ? tableData.map((org) => ({
              key: org?.ID,
              company: org?.Name,
              status: org?.Status || "No Status",
              referredOn: org?.add_time
                  ? moment(org?.add_time).format("DD/MM/YYYY")
                  : "NA",
              lastPayout: org?.last_payout_date
                  ? moment(org?.last_payout_date).format("DD/MM/YYYY")
                  : "NA",
              referralType:
                  org?.AffiliateID === userId ? "Personal Referral" : "Partner Referral",
          }))
        : [];

    const columns = [
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            render: (text: string, record: any) => (
                <div className="firstTd">
                    <Space size="middle">{text}</Space>
                    <Tag color={record.referralType === "Partner Referral" ? "blue" : "green"}>
                        {record.referralType}
                    </Tag>
                </div>
            ),
        },
        {
            title: "Referred On",
            dataIndex: "referredOn",
            key: "referredOn",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: string) => (
                <StatusBtn item={status} />
            ),
        },
        {
            title: "Last Payout",
            dataIndex: "lastPayout",
            key: "lastPayout",
        },
        {
            title: "",
            key: "action",
            render: () => <Button type="link">View</Button>,
        },
    ];

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const paginationConfig = {
        current: currentPage,
        pageSize: pageSize,
        total: dataSource?.length,
        onChange: handlePageChange,
        showSizeChanger: false,
    };

    return (
        <>
            <div
                className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden mt-3 partnerBox"
                style={{
                    width: "100%",
                    boxShadow:
                        "rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px",
                }}
            >
                <Table
                    className="partnerTable Dashboard"
                    style={{ width: "100%" }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={paginationConfig}
                />
                <div className="w-full dashboardBottom bg-white h-20 items-center px-4 flex justify-between gap-4">
                    <span className="text-[#344054] text-[14px] font-medium">
                        Page {currentPage} of {Math.ceil(dataSource.length / pageSize)}
                    </span>

                    <div className="flex items-center gap-3">
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                            disabled={currentPage === Math.ceil(dataSource.length / pageSize)}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardTable;