import React from "react";
import { Row, Col, Typography, Input, Button, Spin } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axiosClient from "../../utils/axios.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";


const InputSection = () => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    message: "",
  };

  const { user } = useAuthContext();

  const handleSubmit = (values, actions) => {
    const data = {
      ...values,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
    };

    axiosClient()
      .post("/contact-us", data)
      .then((res) => {
        toast.success(
          "Thanks for reaching out! We've received your message and will get back to you as soon as possible."
        );
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error) => {
        actions.setSubmitting(false);
        console.log("error", error);
        toast.error("Something wrong");
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <Row className="pt-6 leftForm">
            <Col span={24} className="mt-2">
              <label>Subject</label>
              <Input placeholder="Enter your subject" />
            </Col>
            <Col span={24} className="mt-4">
              <Text className="text-[#344054] text-[14px] font-medium">
                Message
              </Text>
              <Field
                as={TextArea}
                name="message"
                rows={5}
                placeholder="Leave us a message..."
                className="w-full h-[44px] placeholder:text-[#667085] text-[#667085] rounded-[8px] mt-2"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="text-red-500"
              />
            </Col>
            <Col span={24} className="mt-6">
              <Button
                type="primary"
                htmlType="submit"
                disabled={formikProps.isSubmitting}
                className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
              >
                {formikProps.isSubmitting ? <Spin /> : "Send Message"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default InputSection;
