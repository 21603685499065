import React from "react";
import { Col, Row, Typography } from "antd";
import SignupForm from "./form.tsx";
const Main = () => {
  const { Text } = Typography;
  return (
    <>
      <Row style={{background:"#fff"}}>
        <Col span={12}>
          <div className="h-full grid items-center w-full  py-4 theme" style={{background:"#fff"}}>
            <div className="">
              <SignupForm />
              <div className="flex justify-between items-end px-16 pt-24 buttonss">
                <Text className="text-[#475467]">© Glovendor</Text>
                <Text className="text-[#475467]">info@glovendor.io</Text>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <img
            src="https://ik.imagekit.io/8extk8sjo/Sign%20Up%20(3).png?updatedAt=1709110051921"
            width={`100%`}
            className=" h-full object-cover"
          />
        </Col>
      </Row>
    </>
  );
};

export default Main;
